!function ($, Site) {
    var Google_Location_Lookup = function () {
        // constant variables

        var wrapperSelector = '.js-location_lookup',
            placeNameInputSelector = wrapperSelector + '_placename',
            longitudeInputSelector = wrapperSelector + '_longitude',
            latitudeInputSelector = wrapperSelector + '_latitude',
            propertySearchForm = $('#propertySearchForm'),
            $propertySearchInput = $('.js-location_lookup_placename'),
            enterKeyCode = 13;

        // autocomplete options

        var autocompleteOptions = {
            types: ['geocode'],
            componentRestrictions: { country: 'uk' }
        };

        // element variables

        var $locationLookupFields = $(wrapperSelector);

        $locationLookupFields.each(function (index, wrapper) {
            // element variables

            var $wrapper = $(wrapper),
                $placeNameInput = $wrapper.find(placeNameInputSelector),
                $longitudeInput = $wrapper.find(longitudeInputSelector),
                $latitudeInput = $wrapper.find(latitudeInputSelector);

            // autocompelete variables

            var autocomplete = new google.maps.places.Autocomplete($placeNameInput[0], autocompleteOptions);

            // UI bindings

            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                var place = autocomplete.getPlace();

                $latitudeInput.val(place.geometry.location.lat());
                $longitudeInput.val(place.geometry.location.lng());
            });

        });

        $propertySearchInput.on('keyup', debounce(function (e) {
            if (e.keyCode == enterKeyCode) {
                enterKeyPress(e.keyCode);
            }
        }, 300 ))

        var enterKeyPress = function (keycode) {
            if (propertyTypeForm.length) {
                //check to get around location being set to an empty string further up
                if ($('.js-location_lookup_placename').val() != "") {
                    scrollToResults();
                    propertySearchForm.submit();
                }
            }
        }
        function scrollToResults() {
            $('html, body').animate({
                scrollTop: ($('#content-start').offset().top)
            }, 500);
        }
    };

    Site.RegisterModule(Google_Location_Lookup);
}(jQuery, Prodo_Site);