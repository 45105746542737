!function ($, Site) {
    var Prodo_HeroSlider = function () {

      var HeroSlider = $('.js-hero-slider:not(.slick-slider)'),
          HeroSliderBg = $('.js-hero-slider-bg:not(.slick-slider)'),
          arrows = false;

      var prevArrowHtml = '<button class="o-slider__paging-arrowleft slide-prev" data-role="none" aria-label="Previous slide" role="button"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink"xlink:href="/_frontend/images/sprites/_svg-sprite.svg#icon-chevron-left"></use></svg><span class="show-for-sr">Previous</span></button>',
          nextArrowHtml = '<button class="o-slider__paging-arrowright slide-next" data-role="none" aria-label="Next slide" role="button"><svg><use xmlns:xlink="http://www.w3.org/1999/xlink"xlink:href="/_frontend/images/sprites/_svg-sprite.svg#icon-chevron-right"></use></svg><span class="show-for-sr">Next</span></button>';


      if (HeroSlider.length)
      {

        HeroSlider.slick({
          arrows: true,
          prevArrow: prevArrowHtml,
          nextArrow: nextArrowHtml,
          dots: true,
          appendDots: '.o-slider__paging-inner',
          dotsClass: 'm-paging',
          asNavFor: HeroSliderBg
        });

        HeroSliderBg.slick({
          asNavFor: HeroSlider,
          arrows: arrows
        });

      }

    }

    Site.RegisterModule(Prodo_HeroSlider);
}(jQuery, Prodo_Site);
