!function ($, Site) {

    var Prodo_KPI_Dashboard = function () {

        // Constant Variables
        var overlayOpen = false,
            kpiCard = '.js-kpi-card';

        // Elemnet Variables
        var $body = $('body'),
            $overlay = $('.js-kpi-overlay'),
            $overlayContent = $('.js-kpi-overlay-content'),
            $card = $('.js-kpi-card'),
            $overlayOpen = $('.js-kpi-open-overlay'),
            $overlayClose = $('.js-kpi-close-overlay');

        // Functions
        function overlayContent(kpiID) {
            // Initialize data to null
            var data = null;

            // Loop through the kpiData array set in the view
            for (var i = 0; i < kpiData.length; i++) {
                // If the current object's id matches the kpiID, assign it to data and break the loop
                if (kpiData[i].id == kpiID) {
                    data = kpiData[i];
                    break;
                }
            }
        
            // Check if kpiData is not null
            if(data != null) {

                // Declare a variable to store the overlay content
                var overlayContent = "";

                // Populate the overlay content
                overlayContent += '<div class="m-kpi-dashboard__card m-kpi-dashboard__card--overlay m-kpi-dashboard__card--' + data.status + ' js-kpi-card is-visible">';
                    overlayContent += '<h3 class="m-kpi-dashboard__keyfigure">' + data.keyFigure + '</h3>';
                    overlayContent += '<div class="m-kpi-dashboard__divider"></div>';
                    overlayContent += '<p class="m-kpi-dashboard__title">' + data.title + '</p>';
                    overlayContent += '<div class="m-kpi-dashboard__details">';
                        overlayContent += data.details;
                    overlayContent += '</div>';
                    overlayContent += '<div class="m-kpi-dashboard__overview">';
                        if(data.trendText != "") {
                            overlayContent += '<div class="m-kpi-dashboard__trend m-kpi-dashboard__trend--' + data.trend + '">';
                                overlayContent += '<span class="m-kpi-dashboard__trend-icon"></span>';
                                overlayContent += '<span class="m-kpi-dashboard__trend-text">' + data.trendText + '</span>';
                            overlayContent += '</div>';
                        }
                        if(data.target != "") {
                            overlayContent += '<p class="m-kpi-dashboard__target">';
                                overlayContent += '<span class="m-kpi-dashboard__icon icon"></span>';
                                overlayContent += 'Target: <span> ' + data.target + '</span>';
                            overlayContent += '</p>';
                        }
                        if(data.sampleSize != 0) {
                            overlayContent += '<p class="m-kpi-dashboard__sample">';
                                overlayContent += '<span class="m-kpi-dashboard__icon icon"></span>';
                                overlayContent += 'Sample: <span> ' + data.sampleSize + '</span>';
                            overlayContent +='</p>';
                        }
                        if(data.date != "") {
                            overlayContent += '<p class="m-kpi-dashboard__date">';
                                overlayContent += '<span class="m-kpi-dashboard__icon icon"></span>';
                                overlayContent += '<span> ' + data.date + '</span>';
                            overlayContent +='</p>';
                        }
                    overlayContent += '</div>';
                overlayContent += '</div>';

                // Replace the existing content of Overlay
                $overlayContent.append(overlayContent);

            }
        }

        function closeOverlay() {
            $body.removeClass('is-locked');
            $overlay.removeClass('is-open');
            $overlayContent.find(kpiCard).remove();
            overlayOpen = false;
        }

        function openOverlay(kpiID ) {
            $body.addClass('is-locked');
            $overlay.addClass('is-open');
            overlayOpen = true;
            overlayContent(kpiID);
        }

        function isInViewport(element) {
            var rect = element.getBoundingClientRect();
            var html = document.documentElement;
        
            // Calculate the height and width of the element
            var elementHeight = rect.height;
            var elementWidth = rect.width;
        
            // Check if at least 50% of the element is in the viewport
            return (
                rect.top + elementHeight * 0.5 >= 0 &&
                rect.left + elementWidth * 0.5 >= 0 &&
                rect.bottom - elementHeight * 0.5 <= (window.innerHeight || html.clientHeight) &&
                rect.right - elementWidth * 0.5 <= (window.innerWidth || html.clientWidth)
            );
        }

        function showCardsOnScroll() {
            $card.each(function() {
                if (isInViewport(this)) {
                    $(this).addClass('is-visible');
                }
            });
        }

        // UI Bindings
        $overlayOpen.on('click', function () {
            var kpiID = $(this).parents(kpiCard).data('id');
            openOverlay(kpiID);
        });

        $overlayClose.on('click', function () {
            closeOverlay();
        });

        $(window).on('load', function() {
            showCardsOnScroll();
        });
        
        $(window).on('scroll', showCardsOnScroll);

	};

    Site.RegisterModule(Prodo_KPI_Dashboard);

}(jQuery, Prodo_Site);
