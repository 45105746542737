!function ($, Site) {
    var Prodo_Font_Size = function () {

	    var usersCookieFont = getCookie('font-size');

	    if (usersCookieFont) {
	        $('html').addClass(usersCookieFont);
	        Site.TriggerEvent('reinitRotators');

        	// heights changed 

	    }

	    $('.js-text_size').on('click', function () {

		    // make sure everythings layed out correctly
		    
		    var newSize,
		    	newTheme = $(this).data('text_size'),
		    	usersCookieFont = getCookie('font-size');

		    if (newTheme === 'up') {
		        switch (usersCookieFont) {
		            case "font_0":
		                newSize = "font_1";
		                break;
		            case "font_1":
		                newSize = "font_2";
		                break;
		            case "font_2":
		                newSize = usersCookieFont;
		                break;
		            default:
		                newSize = "font_1";
		        }
		    }
		    else if (newTheme === 'down') {
		        switch (usersCookieFont) {
		            case "font_1":
		                newSize = "font_0";
		                break;
		            case "font_2":
		                newSize = "font_1";
		                break;
		            default:
		                newSize = "font_0";
		        }
		    } else {
		    	switch (usersCookieFont) {
		            default:
		                newSize = "font_0";
		        }
		    }

		    //Remove old font classes and add new one

		    $('html').removeClass(function (index, css) {
		        return (css.match(/\bfont_\S+/g) || []).join(' ');
		    }).addClass(newSize);

		    //Create font cookie

		    createCookie("font-size", newSize, 0, 0, 182);

	        //setTimeout(function () { $(document).foundation('reflow'); }, 200);

	    });

    };

    Site.RegisterModule(Prodo_Font_Size);
}(jQuery, Prodo_Site);