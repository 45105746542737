!function ($, Site) {
    var Prodo_DataSlider = function () {

      function getParameterByName(name, url) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, " "));
      }

      if (document.getElementById('priceSliderSale')) {

        var priceSliderSale = document.getElementById('priceSliderSale'),
        priceMinSale = parseInt(priceSliderSale.getAttribute('data-price-min')),
        priceMaxSale = parseInt(priceSliderSale.getAttribute('data-price-max')),
        priceSliderSaleMin = document.getElementById('priceSliderSale-value-min'),
        priceSliderSaleMax = document.getElementById('priceSliderSale-value-max'),
        priceSliderSaleValMin = document.getElementById('priceSliderSaleValMin'),
        priceSliderSaleValMax = document.getElementById('priceSliderSaleValMax');

        noUiSlider.create(priceSliderSale, {
          start: [priceMinSale, priceMaxSale],
          connect: true,
          step: 5000,
          range: {
              'min': priceMinSale,
              'max': priceMaxSale
          },
          format: wNumb({
              decimals: 0,
              thousand: ',',
              prefix: '£'
          }),
          handleAttributes: [
              { 'aria-label': 'Lower range value handle' },
              { 'aria-label': 'Upper range value handle' },
          ]
        });

        priceSliderSaleValMin.value = priceMinSale;
        priceSliderSaleValMax.value = priceMaxSale;

        priceSliderSale.noUiSlider.on('update', function (values, handle) {
            if (handle) {
                priceSliderSaleMax.innerHTML = values[1];
            } else {
                priceSliderSaleMin.innerHTML = values[0];
            }
        });

        priceSliderSale.noUiSlider.on('change', function (values, handle) {

          var price_valMin = values[0],
              price_valMax = values[1];

          price_valMin = price_valMin.replace("£", "").replace(",", "");
          priceSliderSaleValMin.value = price_valMin;

          price_valMax = price_valMax.replace("£", "").replace(",", "");
          priceSliderSaleValMax.value = price_valMax;

          Prodo_Site.TriggerEvent('Reload_Prodo_PropertySearch_Form_Submit');

        });

        if(document.location.search.length) {

          var QSpriceMinSale = getParameterByName('SalePriceMin');
          var QSpriceMaxSale = getParameterByName('SalePriceMax');

          if(QSpriceMinSale != null || QSpriceMaxSale != null) {

              if (QSpriceMinSale == null) {
                  QSpriceMinSale = priceMinSale;
              }

              if (QSpriceMaxSale == null) {
                  QSpriceMaxSale = priceMaxSale;
              }

            priceMinSale = parseInt(QSpriceMinSale);
            priceMaxSale = parseInt(QSpriceMaxSale);

            priceSliderSale.noUiSlider.updateOptions({
              start: [QSpriceMinSale, QSpriceMaxSale]
            });

          }

        }

      }

      if (document.getElementById('priceSliderRent')) {

        var priceSliderRent = document.getElementById('priceSliderRent'),
        priceMinRent = parseInt(priceSliderRent.getAttribute('data-price-min')),
        priceMaxRent = parseInt(priceSliderRent.getAttribute('data-price-max')),
        priceSliderRentMin = document.getElementById('priceSliderRent-value-min'),
        priceSliderRentMax = document.getElementById('priceSliderRent-value-max'),
        priceSliderRentValMin = document.getElementById('priceSliderRentValMin'),
        priceSliderRentValMax = document.getElementById('priceSliderRentValMax');

        noUiSlider.create(priceSliderRent, {
            start: [priceMinRent, priceMaxRent],
            connect: true,
            step: 50,
            range: {
                'min': priceMinRent,
                'max': priceMaxRent
            },
            format: wNumb({
                decimals: 0,
                thousand: ',',
                prefix: '£'
            }),
            handleAttributes: [
                { 'aria-label': 'Lower range value handle' },
                { 'aria-label': 'Upper range value handle' },
            ]
        });

        priceSliderRentValMin.value = priceMinRent;
        priceSliderRentValMax.value = priceMaxRent;

        priceSliderRent.noUiSlider.on('update', function (values, handle) {
            if (handle) {
                priceSliderRentMax.innerHTML = values[1];
            } else {
                priceSliderRentMin.innerHTML = values[0];
            }
        });

        priceSliderRent.noUiSlider.on('change', function (values, handle) {

            var price_valMin = values[0],
                price_valMax = values[1];

            price_valMin = price_valMin.replace("£", "").replace(",", "");
            priceSliderRentValMin.value = price_valMin;

            price_valMax = price_valMax.replace("£", "").replace(",", "");
            priceSliderRentValMax.value = price_valMax;

            Prodo_Site.TriggerEvent('Reload_Prodo_PropertySearch_Form_Submit');
        });

        if(document.location.search.length) {

          var QSpriceMinRent = getParameterByName('RentPriceMin');
          var QSpriceMaxRent = getParameterByName('RentPriceMax');

          if(QSpriceMinRent != null || QSpriceMaxRent != null) {

              if (QSpriceMinRent == null) {
                  QSpriceMinRent = priceMinRent;
              }

              if (QSpriceMaxRent == null) {
                  QSpriceMaxRent = priceMaxRent;
              }

            priceMinRent = parseInt(QSpriceMinRent);
            priceMaxRent = parseInt(QSpriceMaxRent);

            priceSliderRent.noUiSlider.updateOptions({
              start: [QSpriceMinRent, QSpriceMaxRent]
            });

          }

        }

      }

      if (document.getElementById('roomSlider')) {

        var roomSlider = document.getElementById('roomSlider'),
            roomMin = parseInt(roomSlider.getAttribute('data-room-min')),
            roomMax = parseInt(roomSlider.getAttribute('data-room-max')),
            roomSliderMin = document.getElementById('roomSlider-value-min'),
            roomSliderMax = document.getElementById('roomSlider-value-max'),
            roomSliderValMin = document.getElementById('roomSliderValMin'),
            roomSliderValMax = document.getElementById('roomSliderValMax');
          
            if (roomSliderValMin && roomSliderValMin.value === "") {
                roomSliderValMin.value = roomMin;
            }
            if (roomSliderValMax && roomSliderValMax.value === "") {
                roomSliderValMax.value = roomMax;
            }

        noUiSlider.create(roomSlider, {
            start: [roomMin, roomMax],
            connect: true,
            step: 1,
            range: {
                'min': roomMin,
                'max': roomMax
            },
            format: wNumb({
                decimals: 0
            }),
            handleAttributes: [
                { 'aria-label': 'Lower range value handle' },
                { 'aria-label': 'Upper range value handle' },
            ]
            });

        roomSliderValMin.value = roomMin;
        roomSliderValMax.value = roomMax;

        roomSlider.noUiSlider.on('update', function (values, handle) {
            if (handle) {

                roomSliderMax.innerHTML = Math.round(values[handle]);

                if (handle == 0) {
                    roomSliderMax.innerHTML = 'Studio';
                }

            } else {
                roomSliderMin.innerHTML = Math.round(values[handle]);
            }
        });

        roomSlider.noUiSlider.on('change', function (values, handle) {

          var room_valMin = values[0],
              room_valMax = values[1];

          roomSliderValMin.value = room_valMin;
          roomSliderValMax.value = room_valMax;

          Prodo_Site.TriggerEvent('Reload_Prodo_PropertySearch_Form_Submit');

        });

        if(document.location.search.length) {

          var QSroomMin = getParameterByName('BedroomMin');
          var QSroomMax = getParameterByName('BedroomMax');          

          if (QSroomMin != null || QSroomMax != null) {

              if (QSroomMin == null) {
                  QSroomMin = roomMin
              }

              if (QSroomMax == null) {
                  QSroomMin = roomMax
              }

            roomMin = parseInt(QSroomMin);
            roomMax = parseInt(QSroomMax);

            roomSlider.noUiSlider.updateOptions({
              start: [QSroomMin, QSroomMax]
            });

          }

        }

      }

      if (document.getElementById('distanceSlider')) {

        var priceSlider = document.getElementById('distanceSlider'),
            //distanceMin = parseInt(distanceSlider.getAttribute('data-distance-min')),
            distanceMax = parseInt(distanceSlider.getAttribute('data-distance-max')),
            distanceSliderMin = document.getElementById('distanceSlider-value-min'),
            distanceSliderMax = document.getElementById('distanceSlider-value-max'),
            distanceSliderVal = document.getElementById('distanceSliderVal');

        noUiSlider.create(distanceSlider, {
            start: 5,
            behaviour: 'snap',
            connect: [true, false],
            step: 1,
            range: {
                'min': 1,
                'max': distanceMax
            },
            format: wNumb({
                decimals: 0
            }),
            handleAttributes: [
                { 'aria-label': 'Handle for distance slider control' },
            ]

        });

        //distanceSliderVal.value = distanceMax;

        distanceSlider.noUiSlider.on('update', function (values, handle) {
          distanceSliderMax.innerHTML = values[handle] + ' miles';
        });

        distanceSlider.noUiSlider.on('change', function (values, handle) {

          var distance_val = values[0];

          distanceSliderVal.value = distance_val;

          Prodo_Site.TriggerEvent('Reload_Prodo_PropertySearch_Form_Submit');

        });

        if(document.location.search.length) {

          var QSdistance = getParameterByName('Distance');

          if(QSdistance != null) {

            distanceMax = parseInt(QSdistance);

            distanceSlider.noUiSlider.updateOptions({
              start: [QSdistance]
            });

          }

        }

    }

  }

    Site.RegisterModule(Prodo_DataSlider);
}(jQuery, Prodo_Site);
