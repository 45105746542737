!function ($, Site) {
    var Prodo_ListingToggle = function () {
        var toggleViewType = $('.js-toggle-list-view'),
            mapView = $('.js-toggle-list-view__map'),
            listingsView = $('.js-toggle-list-view__listings'),
            pagination = $('.js-toggle-list-view__pagination')

        toggleViewType.on('click', function () {
            toggleViewType.attr('aria-selected', 'false');

            toggleViewType.removeClass('active');
            $(this).addClass('active').attr('aria-selected', 'true');

            var target = $(this).data('list-mode');

            if (target == 'map') {
                listingsView.hide().attr('tabindex', '-1');
                mapView.show().attr('tabindex', '0');
                pagination.hide();
            } else if (target == 'grid') {
                mapView.hide();
                listingsView.show().removeClass('list-view').addClass('grid-view').attr('tabindex', '0');
                pagination.show();
            } else {
                mapView.hide().attr('tabindex', '-1');
                listingsView.show().removeClass('grid-view').addClass('list-view');
                pagination.show();
            }
        });
    };

    Site.RegisterModule(Prodo_ListingToggle);
    Site.RegisterEvent('Reload_Prodo_Toggler', Prodo_ListingToggle);
}(jQuery, Prodo_Site);