!function ($, Site) {
    var Prodo_SiteSearch = function () {

        var search_trigger = $('[data-site-search]'),
            search_bar = $('[data-site-search-bar]'),
            search_label_inactive = $('[data-site-search-label="inactive"]'),
            search_label_active = $('[data-site-search-label="active"]'),
            search_input = $('[data-site-search-input]'),
            search_clear = $('[data-site-search-clear]');

        search_trigger.on('click', function () {

            if ($(this).hasClass('active')) {
                search_label_active.hide();
                search_label_inactive.show();
            } else {
                search_label_active.show();
                search_label_inactive.hide();
            }

            $(this).toggleClass('active');
            search_bar.toggle();
            search_input.focus();
        });

        search_clear.on('click', function () {
            search_input.val('');
        });

    };

    Site.RegisterModule(Prodo_SiteSearch);

}(jQuery, Prodo_Site);
