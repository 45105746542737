!function ($, Site) {

  var Prodo_Selectric = function () {

      $('.js-select, .umbraco-forms-field-wrapper select').selectric();

      $(document).ready(function () {
          $(".selectric-input").wrap("<label class='selectric__visuallyhidden' aria-hidden='true'>Hidden Label</label>");
      });

  };

  Site.RegisterModule(Prodo_Selectric);

}(jQuery, Prodo_Site);
