!function ($, Site) {

  var Prodo_Accessibility = function () {

    var accessibility_menu = $('.js-accessibility-toolbar'),
        accessibility_menu_handle = $('.js-accessibility-toolbar--handle');

      $('[data-task="js-show_accessibility"]').on('click', function(event) {

      	$('body').toggleClass('js-prevent_scroll');
      	$('.m-accessibility_menu').toggleClass('m-accessibility_menu--active');
      	Site.TriggerEvent('reinitRotators');

      	// heights changed
        
      });

      //Enter key event for accessibility
      $('.m-accessibility_toolbar__button').on('keydown', function (event) {
          if (event.which == 13) {
              $(this).trigger("click");
          }
      });


      var openAccessibilityMenu = function () {
        accessibility_menu.addClass('open');
      }

      var closeAccessibilityMenu = function () {
        accessibility_menu.removeClass('open');
        accessibility_menu_handle.removeClass('active');
        createCookie("hideAccessibilityMenu", 'true', 0, 0, 182);
      }

      accessibility_menu_handle.on('click', function () {

        if ($(this).hasClass('active')) {
          closeAccessibilityMenu();
        } else {
            accessibility_menu_handle.addClass('active');
            openAccessibilityMenu();
            //Give tab focus
            $('[data-task="js-show_accessibility"]').focus();
        }

      });

   };

   Site.RegisterModule(Prodo_Accessibility);

}(jQuery, Prodo_Site);
