!function($, Site) {

    var Prodo_PropertyRequestInformation = function() {

        var propertyName = $('.property input');

        if (getParameterByName("prop", null))
            propertyName.val(getParameterByName("prop", null));

        function getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
    }

    Site.RegisterModule(Prodo_PropertyRequestInformation);

}(jQuery, Prodo_Site);