!function ($, Site) {
    var Prodo_NewsFilter = function () {

        var brandName = $('#brand-name');

        function filterNews() {
            if (brandName.length > 0) {

                var url = $('#brand-name').val();

                window.location.href = url;
            }
        }

        Site.RegisterEvent("Reload_Prodo_NewsFilter_Brand_Changed", filterNews);
    }

    Site.RegisterModule(Prodo_NewsFilter);
}(jQuery, Prodo_Site);