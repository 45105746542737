!function ($, Site) {

//Dynamically adds title attribute to videos rendered in grid via Embed module
  var Prodo_Embed = function () {

      $(document).ready(function () {
          $("div.video-wrapper iframe").attr("title", "Embedded video from YouTube");
      })

   };

   Site.RegisterModule(Prodo_Embed);

}(jQuery, Prodo_Site);
