!function ($, Site) {
    var Prodo_Json_Map = function () {

        // constant variables

        var markers;

        // element variables

        var $maps = $('.js-map-canvas');

        if ($maps.length) {
            // initializes the map
            var initMaps = function ($maps) {
                $maps.each(function (index, mapElement) {
                    var $map = $(mapElement),
                        map = ProdoMapHelpers.InitializeMap(mapElement),
                        locationsJson = $map.data('locations-json');

                    markers = ProdoMapHelpers.AddMarkersFromJson(map, locationsJson, false);
                    ProdoMapHelpers.BindDefaultMapEvents(map);
                });
            };

            // ui bindings

            google.maps.event.addDomListener(window, 'load', function () {
              initMaps($maps);
            });

            //Trigger maps in tab content
            $('.js-toggle-list-view').on('click.json-map', function(){

              setTimeout(function(){
                initMaps($maps);
              }, 100);

            });

            $('[data-task="map_interact"]').each(function(){
                $(this).off('click.json-map').on('click.json-map', function () {

                    var id = $(this).attr('id');

                    var markerToClick = markers.filter(function(marker) {
                        return marker.id === id;
                    })[0];

                    google.maps.event.trigger(markerToClick, 'click');

                });
            });

            $('.tabs-title, .accordion-title').click(function () {

              setTimeout(function() {

                window.dispatchEvent(new Event('resize'));

                if ($maps.length) {
                  initMaps($maps);
                }

              }, 100);

            });

        }
    };

    Site.RegisterModule(Prodo_Json_Map);
    Site.RegisterEvent('Reload_Prodo_Json_Map', Prodo_Json_Map);
}(jQuery, Prodo_Site);
