!function ($, Site) {
    var Prodo_ContrastChange = function () {

    	// setup contrast on pageload

    	var usersCookie = getCookie('contrast-theme');

	    if (usersCookie) {
	        $('body').attr('id', usersCookie);
	    }

	    // change contrast on user input

	    var $contrastBtn = $('.js-themechange');

	    $contrastBtn.on('click', function () {

		    var themeName = "contrast_theme",
		    	currentTheme = $('body').attr('id'),
		    	newTheme = $(this).data('theme');

		    if (newTheme === 'default') {

		    	$('body').removeAttr('id');
		        deleteCookie("contrast-theme");

		    } else {

		    	// update theme
		    	$('body').removeAttr('id').attr('id', newTheme);

		        //Create font cookie
		        createCookie("contrast-theme", newTheme, 0, 0, 182);
		    }

	    });

    };

    Site.RegisterModule(Prodo_ContrastChange);
}(jQuery, Prodo_Site);