!function ($, Site) {

	var Prodo_PanicButton = function() {

		

        var $panicButton = $(".js-panic-button");

        $panicButton.headroom();
		

        $panicButton.click(function (event) {
	        var panicButtonUrl = $(this).attr("href");

	        event.preventDefault();

	        document.body.innerHTML = '';

	        var url = "/";

            if (panicButtonUrl) {
	            var redirectUrl = panicButtonUrl;
            } else {
	            var redirectUrl = url;
            }

	        if (history.replaceState) {
		        //alert('supported');
		        window.history.replaceState("", "", url); //replace current history entry
	        }

	        window.location.replace(redirectUrl);
        });

	};

	Site.RegisterModule(Prodo_PanicButton);

}(jQuery, Prodo_Site);
