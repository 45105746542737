!function ($, Site) {
    var Prodo_AccessibleMegaMenu = function () {
      
      if( Foundation.MediaQuery.is('large') ) {
        $('.js-accessible-megamenu').accessibleMegaMenu();
      };
        
    };

    Site.RegisterModule(Prodo_AccessibleMegaMenu);
}(jQuery, Prodo_Site);