!function ($, Site) {

  var Prodo_InterchangeFade = function () {

    $('[data-interchange]').on('load', function () {
       $(this).css('opacity', '1');
    });

  };

  Site.RegisterModule(Prodo_InterchangeFade);

}(jQuery, Prodo_Site);
