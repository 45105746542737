!function ($, Site) {
    var Prodo_PropertySearch = function () {
        var
            order = $('#property-order'),
            distance = $('#distanceSliderVal'),
            eventDate = $('#event-date'),
            toRentBtn = $('#to-rent'),
            performSearchButton = $('#perform-search'),
            forRentBtn = $('#for-rent-button'),
            roomSliderMin = $('#roomSliderValMin'),
            roomSliderMax = $('#roomSliderValMax'),
            rentPriceMin = $('#priceSliderRentValMin'),
            rentPriceMax = $('#priceSliderRentValMax'),
            salePriceMin = $('#priceSliderSaleValMin'),
            salePriceMax = $('#priceSliderSaleValMax'),
            typeOfCare = $('#type-of-care'),
            propertyTypeBtn = $('.m-toggle-button__input'),
            propertyTypeForm = $('#propertyTypeForm'),
            propertySearchForm = $('#propertySearchForm'),
            currentSize = Foundation.MediaQuery.current;

        function buildUrl() {
            Prodo_Site.TriggerEvent('Reload_Prodo_Toggler');
            Prodo_Site.TriggerEvent('Reload_Prodo_Json_Map');

            var u = [];

            //get current url
            var url = window.location.href.split('?')[0];

            //get property type
            propertyTypeForm.find('input[type="radio"]:checked').each(function (i, obj) {
                u.push('PropertyType=' + $(obj).val());
            })

            //get property location
            propertyTypeForm.find('input[type="text"][name="Location"]').each(function (i, obj) {
                if ($(obj).val())
                    u.push('Location=' + $(obj).val());
            })
            propertyTypeForm.find('input[type="hidden"][name="Lat"]').each(function (i, obj) {
                if ($(obj).val())
                    u.push('Lat=' + $(obj).val());
            })
            propertyTypeForm.find('input[type="hidden"][name="Lon"]').each(function (i, obj) {
                if ($(obj).val())
                    u.push('Lon=' + $(obj).val());
            })

            //get rent price
            if ($('input[type="radio"]:checked.m-toggle-button__input').length) {
                switch ($('input[type="radio"]:checked.m-toggle-button__input').val()) {
                    case 'PropertyForSale':
                    case 'PropertyForRetirement':
                        if (salePriceMin.length && salePriceMin.val())
                            u.push('SalePriceMin=' + $(salePriceMin).val());
                        if (salePriceMax.length && salePriceMax.val())
                            u.push('SalePriceMax=' + $(salePriceMax).val());
                        break;
                    case 'PropertyToRent':
                        if (rentPriceMin.length && rentPriceMin.val())
                            u.push('RentPriceMin=' + $(rentPriceMin).val());
                        if (rentPriceMax.length && rentPriceMax.val())
                            u.push('RentPriceMax=' + $(rentPriceMax).val());
                        break;
                }
            }

            //get sort
            if (typeOfCare.find('option:selected').length)
                u.push('TypeOfCare=' + typeOfCare.find('option:selected').val());

            //get bedrooms
            if (roomSliderMin.length && roomSliderMin.val())
                u.push('BedroomMin=' + $(roomSliderMin).val());
            if (roomSliderMax.length && roomSliderMax.val())
                u.push('BedroomMax=' + $(roomSliderMax).val());

            //get distance
            if (distance.length && distance.val())
                u.push('Distance=' + $(distance).val());

            //get sort
            if (order.find('option:selected').length)
                u.push('Order=' + order.find('option:selected').val());

            if (u.length > 0) {
                url += '?' + u.join("&");
            }

            window.history.replaceState(null, null, url);
        }

        function submitForm() {
            checkLocation();
            propertySearchForm.submit();
        }

        function checkLocation() {
            //if lon || lat == null throw error
            var location = propertyTypeForm.find('.js-location_lookup_placename');
            var lon = propertyTypeForm.find('.js-location_lookup_longitude');
            var lat = propertyTypeForm.find('.js-location_lookup_latitude');

            if ((!lon.val() || !lat.val()) && (location.length && location.val())) {
                $(location).val('');
                $(location).attr('placeholder', 'Please select valid location');
            }
        }

        function clearFilters() {
            // set dropdwons to first option and call refresh selectric in order to reflect selected values
            if (typeOfCare.length > 0) {
                typeOfCare.prop("selectedIndex", 0).selectric('refresh');
            }

            if (order.length > 0) {
                order.prop("selectedIndex", 0).selectric('refresh');
            }

            if (salePriceMin.length && salePriceMax.length) {
                var salesPriceTop = $('#salePriceMax').val();

                salePriceMin.val('0');
                salePriceMax.val(salesPriceTop);

                priceSliderSale.noUiSlider.updateOptions({
                    start: [0, salesPriceTop]
                });
            }

            if (roomSliderMin.length && roomSliderMax.length) {
                roomSliderMin.val('0');
                roomSliderMax.val('5');

                roomSlider.noUiSlider.updateOptions({
                    start: [0, 5]
                });
            }

            if (distance.length) {
                distance.val('50');

                distanceSlider.noUiSlider.updateOptions({
                    start: [50]
                });
            }

            var location = propertyTypeForm.find('.js-location_lookup_placename');
            if (location.length) {
                location.val('');
                propertyTypeForm.find('.js-location_lookup_longitude').val('');
                propertyTypeForm.find('.js-location_lookup_latitude').val('');
            }

            submitForm();
        }

        //clear lon/lat on key down
        propertyTypeForm.find('.js-location_lookup_placename').keydown(function () {
            propertyTypeForm.find('.js-location_lookup_longitude').val('');
            propertyTypeForm.find('.js-location_lookup_latitude').val('');
        })

        $('#priceSliderSaleWrapper').hide();
        $('#priceSliderRentWrapper').hide();

        $(document).ready(function () {
            if ($('input[type="radio"]:checked.m-toggle-button__input').length) {
                switch ($('input[type="radio"]:checked.m-toggle-button__input').val()) {
                    case 'PropertyForSale':
                    case 'PropertyForRetirement':
                        $('#priceSliderSaleWrapper').show();
                        break;
                    case 'PropertyToRent':
                        $('#priceSliderRentWrapper').show();
                        break;
                }
            }
        });

        $(window).on("resize.zf.mediaquery", function () {
            var newSize = Foundation.MediaQuery.current;
            if (currentSize != newSize) {
                $('[data-equalizer]').foundation('_reflow');
                currentSize = newSize;
            }
        });

        propertyTypeBtn.on('click', function () {
            $('#priceSliderSaleWrapper').hide();
            $('#priceSliderRentWrapper').hide();

            switch ($(this).val()) {
                case 'PropertyForSale':
                case 'PropertyForRetirement':
                    $('#priceSliderSaleWrapper').show();
                    break;
                case 'PropertyToRent':
                    $('#priceSliderRentWrapper').show();
                    break;
            }
        });

        function scrollToResults() {
            $('html, body').animate({
                scrollTop: ($('#content-start').offset().top)
            }, 500);
        }



        performSearchButton.on('click', function () {
            scrollToResults();
            $('#for-sale')[0].click();
        });

        forRentBtn.on('click', function () {
            scrollToResults();
            $('#for-rent')[0].click();
        });

        Site.RegisterEvent("Reload_Prodo_PropertySearch_Form_Submit", submitForm);
        Site.RegisterEvent("Reload_Prodo_Rebuild_PropertySearch_Url", buildUrl);
        Site.RegisterEvent("Clear_Prodo_PropertySearch_Form_Submit", clearFilters);
    };

    Site.RegisterModule(Prodo_PropertySearch);
}(jQuery, Prodo_Site);