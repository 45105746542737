!function ($, Site) {
    var Prodo_InteractiveMap = function () {

      /* add some functions for adding/removing CSS classes - https://toddmotto.com/hacking-svg-traversing-with-ease-addclass-removeclass-toggleclass-functions/ */
      SVGElement.prototype.hasClass = function (className) {
          return new RegExp('(\\s|^)' + className + '(\\s|$)').test(this.getAttribute('class'));
      };

      SVGElement.prototype.addClass = function (className) {
          if (!this.hasClass(className)) {
              this.setAttribute('class', this.getAttribute('class') + ' ' + className);
          }
      };

      SVGElement.prototype.removeClass = function (className) {
          var removedClass = this.getAttribute('class').replace(new RegExp('(\\s|^)' + className + '(\\s|$)', 'g'), '$2');
          if (this.hasClass(className)) {
              this.setAttribute('class', removedClass);
          }
      };

      $('#interactivemap [data-element]').hover(function()
      {
        this.addClass('hover');
        $(this).click();

      }, function()
      {
        this.removeClass('hover');
      });

      $('[data-tooltip-close]').click(function()
      {
          //Removes the grey hover and the tooltip
          tooltipClose();
      });

      function isAreaSet(id)
      {
        return getAreaJson(id) != null;
      }

      $('#interactivemap [data-element]').click(function(e)
      {
          tooltipClose();

          var areaId = $(this).attr('id');
          this.addClass('active');
          processTooltipFromId(areaId, e, this);

      });


      $('#interactivemapselect').change(function(e)
      {
          tooltipClose();

          var areaId = $(this).val();

          // Map is hidden
          if ($(window).width() <= 642)
          {
              //document.location.href = getAreaLink(areaId);
          }
          else
          {
              $('#interactivemap [data-element]').each(function()
              {
                  if ($(this).attr('id') == areaId)
                  {
                      $(this).trigger('click');
                      return;

                  }
              });
          }


      });

      function processTooltipFromId(areaId, e, path)
      {

          if (getAreaJson(areaId) != null)
          {
              var type = getRawType(areaId);

              var name = getRawAreaFormattedName(areaId);
              var areaDataLabel1 = getRawAreaDataLabel1(areaId);
              var areaDataInfo1 = getRawAreaDataInfo1(areaId);
              var areaDataLabel2 = getRawAreaDataLabel2(areaId);
              var areaDataInfo2 = getRawAreaDataInfo2(areaId);

              var areaDataLabel3 = getRawAreaDataLabel1(areaId);
              var areaDataInfo3 = getRawAreaDataInfo2(areaId);
              var areaDataLabel4 = getRawAreaDataLabel1(areaId);
              var areaDataInfo4 = getRawAreaDataInfo2(areaId);

              $('[data-interactive-map-tooltip-info-1]').empty();
              $('[data-interactive-map-tooltip-label-1]').empty();

              if (type === 'office') {

                  var officeName = getRawOfficeName(areaId);
                  var officeAddress = getRawOfficeAddress(areaId);
                  if (officeAddress != null)
                  {

                    officeAddress= officeAddress.replace(/,/g,'<br/>');

                  }

                  var officeTel = getRawOfficeTel(areaId);

              }
              else {
                $('[data-interactive-map-tooltip-label-1]').empty().html(areaDataLabel1);
                $('[data-interactive-map-tooltip-info-1]').empty().html(areaDataInfo1);
              }

              if (name == null) {
                  console.log("Area not found - ensure that the InteractiveMap paths have an ID that matches a field in the JS (GlobalNetworkLocations)");
              }

              $('[data-interactive-map-tooltip-title]').empty().html(name);


              $('[data-interactive-map-tooltip-label-2]').empty();

              if (areaDataLabel2) {
                  $('[data-interactive-map-tooltip-label-2]').empty().html(areaDataLabel2);
              }

              $('[data-interactive-map-tooltip-info-2]').empty();

              if (areaDataInfo2) {
                  $('[data-interactive-map-tooltip-info-2]').html(areaDataInfo2);
              }

              if (areaDataLabel3) {
                  $('[data-interactive-map-tooltip-label-3]').empty().html(areaDataLabel3);
              }

              $('[data-interactive-map-tooltip-info-3]').empty();

              if (areaDataInfo3) {
                  $('[data-interactive-map-tooltip-info-3]').html(areaDataInfo3);
              }

              if (areaDataLabel4) {
                  $('[data-interactive-map-tooltip-label-4]').empty().html(areaDataLabel3);
              }

              $('[data-interactive-map-tooltip-info-4]').empty();

              if (areaDataInfo4) {
                  $('[data-interactive-map-tooltip-info-4]').html(areaDataInfo4);
              }

              $('[data-interactive-map-tooltip-office-name]').empty();

              if (officeName) {
                  $('[data-interactive-map-tooltip-office-name]').html(officeName);
              }

              $('[data-interactive-map-tooltip-office-address]').empty();

              if (officeAddress) {
                  $('[data-interactive-map-tooltip-office-address]').html(officeAddress);
              }

          $('[data-interactive-map-tooltip-office-tel]').empty();

          if(officeTel){
            $('[data-interactive-map-tooltip-office-tel]').html(officeTel);
          }

              $('#interactive-tooltip').addClass('active');

              var topOffset = 0; //px

              var pathBbox = path.getBoundingClientRect();


              // Left calculation
              // Center of the svg path
              var pathCenterX = $(path).offset().left + (pathBbox.width / 2),
                  pathCenterY = $(path).offset().top + (pathBbox.height / 2);

              // Get tooltip width and height
              var tooltipCenterX = $('#interactive-tooltip').outerWidth();
              var tooltipCenterY = $('#interactive-tooltip').outerHeight() + 15;

              var mainWidth = $('.m-map-listings').outerWidth() + $('.m-map-listings').offset().left,
                  newLeftPosition = pathCenterX - (tooltipCenterX / 2),
                  tooltipFinalX = newLeftPosition,
                  tooltipFinalY = pathCenterY - tooltipCenterY;

              if (mainWidth <= pathCenterX + (tooltipCenterX / 2)) {
                  $('#interactive-tooltip').addClass('arrow-right');
                  tooltipFinalX = $(path).offset().left - (tooltipCenterX - 30),
                  tooltipFinalY = pathCenterY - ($('#interactive-tooltip').outerHeight() / 2);
              } else {
                  $('#interactive-tooltip').removeClass('arrow-right');
              }

              $('#interactive-tooltip').css('left', tooltipFinalX);
              $('#interactive-tooltip').css('top', tooltipFinalY);
          }
      }

      function tooltipClose()
      {
        $('#interactive-tooltip').removeClass('active');
        $('#interactivemap polygon').each(function()
        {
          this.removeClass('active');
        });
      }

      function getRawType(id)
      {
        return getAreaJson(id).Type;
      }

      function getRawAreaFormattedName(id)
      {
        return getAreaJson(id).Name;
      }

      function getRawAreaDataLabel1(id)
      {
        return getAreaJson(id).AreaDataLabel1;
      }

      function getRawAreaDataInfo1(id)
      {
        return getAreaJson(id).AreaDataInfo1;
      }

      function getRawAreaDataLabel2(id)
      {
        return getAreaJson(id).AreaDataLabel2;
      }

      function getRawAreaDataInfo2(id)
      {
        return getAreaJson(id).AreaDataInfo2;
      }

      function getRawAreaDataLabel3(id)
      {
        return getAreaJson(id).AreaDataLabel3;
      }

      function getRawAreaDataInfo3(id)
      {
        return getAreaJson(id).AreaDataInfo3;
      }

      function getRawAreaDataLabel4(id)
      {
        return getAreaJson(id).AreaDataLabel4;
      }

      function getRawAreaDataInfo4(id)
      {
        return getAreaJson(id).AreaDataInfo4;
      }

      function getRawOfficeName(id)
      {
        return getAreaJson(id).Name;
      }

      function getRawOfficeAddress(id)
      {
        return getAreaJson(id).AreaDataInfo1;
      }

      function getRawOfficeTel(id)
      {
        return getAreaJson(id).officeTel;
      }

      function getAreaJson(id)
      {
          for (var i = 0; i < GlobalNetworkLocations.length; i++) {
              var nameFormatted = GlobalNetworkLocations[i].NameFormatted;
              if (nameFormatted == id)
              {
                return GlobalNetworkLocations[i];
              }
          }
          return null;
      }

    };

    Site.RegisterModule(Prodo_InteractiveMap);
}(jQuery, Prodo_Site);
