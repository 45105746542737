!function ($, Site) {
    var Prodo_JumpMenu = function () {

        var siteJump_trigger = $('.js-site-jump__trigger'),
            siteJump_list = $('.js-site-jump__list'),
            siteJump_current = $('.js-site-jump__current'),
            siteJump_close = $('.js-site-jump__close');

        var close_jump_menu = function () {
            siteJump_list.slideUp();
            siteJump_current.removeClass('active');
        }

        siteJump_trigger.hover(function () {
            siteJump_list.slideDown();
            siteJump_current.addClass('active');

        });

        siteJump_list.hover(function () { }, function () {
        });

        siteJump_trigger.click(function (e) {
            e.stopPropagation();
        });

        $(document).click(function () {
            close_jump_menu();
        });

        $('.m-site-nav__item').hover(function () {
            close_jump_menu();
        });

        siteJump_close.on('click', function () {
            close_jump_menu();
        });



        $('.st-menu__toggle').click(function () {
            $(this).toggleClass('active');
            $(this).find('+ .st-menu__sub-menu').slideToggle();
        });
    }

    Site.RegisterModule(Prodo_JumpMenu);
}(jQuery, Prodo_Site);
