!function ($, Site) {
    var Prodo_RSSFeed = function () {
        var params = new URLSearchParams(window.location.search);
        var $filterOptions = $(".js-rss-feed__dropdown");

        $('.js-rss-feed__dropdown.js-select').selectric().on('change', function () {
            params.set('division', $(this).val());
        });

        $(window).on('load', function () {
            var queryStringFilter = params.get('division');
            if (queryStringFilter) {
                $filterOptions.val(queryStringFilter).selectric('refresh');
            }  
        });

        $(".js-rss-feed__search").click(function () {
            if (params.get('division') != "0") {
                window.location.search = params;
            }
            else {
                window.location.search = "";
            }
        });
    };

    Site.RegisterModule(Prodo_RSSFeed);

}(jQuery, Prodo_Site);
